const DEFAULT_COMPILE_ENV = 'prod';
// 域名
const DefaultConfig: any = {
  dev: {
    baseUrl: '/dev',
    publicUrl: '/',
  },
  prod: {
    baseUrl: 'https://hasee.zaiqizu.com',
    bucket: 'aqz-h5-prod',
    callbackUrl: 'https://h5pt.zaiqizu.com',
  },
};
/** 图片地址 */
const IMGURL = {

};
export {
  IMGURL,
  DefaultConfig,
  DEFAULT_COMPILE_ENV,
};
