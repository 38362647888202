// 度小满绑卡
const BindCard = () => import('../views/bindCard/bindCard.vue');
const Success = () => import('../views/bindCard/success.vue');

export default [
  {
    path: '/bindCard',
    name: '绑定银行卡',
    component: BindCard,
  },
  {
    path: '/bindCard/success',
    name: '绑卡成功',
    component: Success,
  },
];
